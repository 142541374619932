import React from "react"
import Layout from '../../components/layout'; 
import DropdownMenu from '../../components/dropdownMenu'
import productStyles from '../../styles/product.module.scss'
import image from '../../images/vector-image-3.jpg';
import dropdownStyles from '../../styles/dropdown.module.scss'

const EstudioPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <div className={productStyles.descripcion}>
                <h3>Estudio Socioeconómico</h3>
                <p className={productStyles.texto}>Reporte de entrevista domiciliaria y validación de referencias en la cual se recaba, coteja y analiza la información socioeconómica, familiar y laboral de cada candidato en el cual se da la opinión de riesgo respecto a la ubicación de una persona en su contexto social y nivel económico. </p>
            </div>
            <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
        </Layout>
    ) 
}
export default EstudioPage;